import { accessToken } from "./../interfaces/common";
import { Injectable } from "@angular/core";
import { NgZone } from "@angular/core";
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManager,
  AccessToken,
  IDToken,
  UserClaims,
  TokenParams,
  Tokens,
} from "@okta/okta-auth-js";
import { UserService } from "app/old/_services/_user.service";
import { CookieService } from "ngx-cookie-service";
import { StoreService } from "app/old/_services/_store.service";
import { SharedDataService } from "./shared-data.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from "environments/environment";
import { filter } from "rxjs/operators";
import jwt_decode from "jwt-decode";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  userInfo: any;
  private lastSegment: string;
  private renewalTimeout: any;
  oktaAuth = new OktaAuth({
    issuer: `${environment.oktaIssuer}`,
    clientId: `${environment.oktaClientId}`,
    redirectUri: `${environment.oktaRedirectUri}`,
    scopes: ["openid", "profile", "email", "offline_access"],
    tokenManager: {
    autoRenew: true,
    autoRemove: false,
    },

    storageManager: {
      token: {
        storageType: "sessionStorage",
        secure: true,
      },
      cache: {
        storageType: "sessionStorage",
        secure: true,
      },
      transaction: {
        storageType: "sessionStorage",
        secure: true,
      },
     
    },
    
  });
  tokenManager: TokenManager = this.oktaAuth.tokenManager;
  accessToken: AccessToken;
  constructor(
    private sharedDataService: SharedDataService,
    private userService: UserService,
    private cookieService: CookieService,
    private store: StoreService,
    private route: ActivatedRoute,
    public router: Router,
    private ngZone: NgZone
  ) { 
    this.setupEventListeners();
    this.scheduleTokenRenewal();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const urlSegments = event.urlAfterRedirects.split("/");
        this.lastSegment = urlSegments[urlSegments.length - 1];
        console.log(" this.lastSegment", this.lastSegment); // Logs the last segment of the URL
        // if (this.store.get('user') && this.lastSegment == "") {
        //     const isAuthenticated =  this.checkAuthState();
        //    console.log(" this.isAuthenticated", isAuthenticated);  
        // }
      });
      
     }
  async handleAuthentication() {
    try {
      const tokens =
        (await this.oktaAuth.handleLoginRedirect()) as unknown as Tokens;
      if (tokens) {
        this.oktaAuth.tokenManager.setTokens(tokens);
      } else {
        console.error("No tokens returned from handleLoginRedirect");
      }
    } catch (error) {
      console.error("Error handling authentication:", error);
    }
  }

  getToken(): Promise<string | null> {
    return this.oktaAuth.tokenManager
      .get("accessToken")
      .then((token) => {
        return this.ngZone.run(() => {
          if (token) {
            console.log("Access Token Found:");
            return token.accessToken;
          } else {
            console.log("No access token found");
            return null;
          }

        });
      })
      .catch((error) => {
        return this.ngZone.run(() => {
          console.error("Error getting access token", error);
          return null;
        });
      });
  }
   // Handle the login flow
  async login() {
    //  oktaAuth.signInWithRedirect();
    await this.oktaAuth.token.getWithRedirect();
    //getWithRedirect
  }

  // Handle the callback after login
  async handleCallback() {
    try {
      const tokens = await this.oktaAuth.token.parseFromUrl();
      console.log("token 130::");
      this.oktaAuth.tokenManager.setTokens(tokens.tokens);
      console.log("  this.oktaAuth.tokenManager::", this.oktaAuth.tokenManager);
      const userInfo = await this.oktaAuth.token.getUserInfo(
        tokens.tokens.accessToken,
        tokens.tokens.idToken
      );
      let gpid = userInfo.gpid;
      const user = userInfo;
      this.userInfo = userInfo;
      //this.sharedDataService.setUserInfo(user);
      console.log("auth service 143::", user);
      this.store.set("user", JSON.stringify(user));
      this.updateUserSettings(gpid);
      return {
        gpid: gpid,
        oktaAccessToken: tokens.tokens.accessToken.accessToken,
      };
    } catch (error) {
      console.error("Error handling the callback:", error);
      return null;
    }
  }
  // Check authentication state on page load
  async checkAuthState() {
    let loginSuccess = false;
    if (this.oktaAuth.token.isLoginRedirect()) {
    //  let response = await this.handleCallback();
      loginSuccess = true;
      return await this.handleCallback();
     // return response;
    } else {
      // Check if user is already authenticated
      this.oktaAuth.tokenManager
        .get("accessToken")
        .then((accessToken) => {
          if (accessToken && !this.isTokenExpired(accessToken)) {
            console.log("User is authenticated inside checkAuthState else");
            loginSuccess = true;
            this.userInfo =  this.oktaAuth.token.getUserInfo();
            return this.userInfo; 
            //return this.oktaAuth.token.getUserInfo();
          } else {
            // window.location.replace('/')
            // console.log('User is not authenticated');;
            loginSuccess = false;
            throw new Error("User not authenticated");
          }
        })
        .then((userInfo) => {
          // console.log("useInfo3",userInfo)
          // console.log('User is authenticated');
          let gpid = userInfo.gpid;
          const user = userInfo;
          // this.sharedDataService.setUserInfo(user);
          console.log("auth service:184::", user);
          //  this.store.set("user", JSON.stringify(user));
          loginSuccess = true;
          this.updateUserSettings(gpid);
          return { gpid: gpid, oktaAccessToken: this.accessToken.accessToken };
        })
        .catch(() => {
          // window.location.replace('/');
          // console.log('User is not authenticated');
          if (!loginSuccess) {
            this.login();
            return null;
          }
        });
    }
  }

  isTokenExpired(tokenObject: any): boolean {
    if (!tokenObject || !tokenObject.expiresAt) {
      return true;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    return tokenObject.expiresAt < currentTime;
  }
  getLastSegment(): string {
    return this.lastSegment;
  }
  updateUserSettings(gpid) {
    let gpidVal = gpid;
     const lastSegment = this.getLastSegment();
    console.log('Last segment:', lastSegment);
    this.userService.get(gpidVal).then((user: any) => {
      if (user) {
       console.log('Last segmentss:', lastSegment);
       if(lastSegment == "" ){
       sessionStorage.removeItem("user");
        sessionStorage.removeItem("market");
        sessionStorage.removeItem("planningYear");
        sessionStorage.removeItem("workingYear");
        const workingYear = new Date().getFullYear();
        this.store.set("workingYear", workingYear);
        this.sharedDataService.setPlanningYear(workingYear);
        this.sharedDataService.setWorkingYear(workingYear);
        sessionStorage.removeItem("selectedPlan");
        sessionStorage.removeItem("selectedPortfolio");
        this.router.navigate(["/appRouting"]);
       }
        this.userInfo = user;
        this.sharedDataService.setUserInfo(user);
        console.log("auth Service 216::", user);
        this.store.set("user", JSON.stringify(user));
        // this.cookieService.set("userEmail", user.email);
        // this.cookieService.set("gpid", user.gpid);
        // this.cookieService.set(
        //   "userEmail",
        //   user.email,
        //   undefined,
        //   "/",
        //   undefined,
        //   true,
        //   "None"
        // );
        // this.cookieService.set(
        //   "gpid",
        //   user.gpid,
        //   undefined,
        //   "/",
        //   undefined,
        //   true,
        //   "None"
        // );
        // if (user.routingPage == "Y") {
        //   this.router.navigate(["/appRouting"]);
        // } else {
        //   this.navigateToDefault();
        // }
      }
    });
  }
async scheduleTokenRenewal(): Promise<void> {
    const token = await this.oktaAuth.tokenManager.get("accessToken");
    if (token) {
      const expiresIn = token.expiresAt - Math.floor(Date.now() / 1000); // Time in seconds
      const renewBefore = expiresIn - 60; // Renew 1 minute before expiration
      this.renewalTimeout = setTimeout(
        () => this.renewToken(),
        renewBefore * 1000
      );
    }
  }

  private setupEventListeners(): void {
    this.oktaAuth.tokenManager.on("expired", (key) => {
      console.log(`${key} token expired`);
      this.renewToken();
    });
    this.oktaAuth.tokenManager.on("renewed", (key, newToken) => {
      console.log(`${key} token renewed`, newToken);
    });
    this.oktaAuth.tokenManager.on("error", (err) => {
      console.error("Token manager error:", err);
    });
  }

  async renewToken(): Promise<void> {
    try {
      const renewedToken = await this.oktaAuth.tokenManager.renew(
        "accessToken"
      );
      console.log("Token renewed:", renewedToken);
      this.scheduleTokenRenewal();
    } catch (err) {
      console.error("Token renewal failed:", err);
      this.handleRenewalFailure();
    }
  }
  private handleRenewalFailure(): void {
    // Redirect to login if renewal fails
    this.router.navigate(["/login"]);
  }
  navigateToDefault() {
    const { defaultLanding } = this.userInfo;
    const dftLndList = defaultLanding.split(",");
    const defLanLength = dftLndList.length;

    switch (defLanLength) {
      case 1:
        this.navigateToMarket();
        break;
      case 2:
        this.navigateToPortfolio(dftLndList[defLanLength - 1]);
        break;
      case 3:
        this.navigateToPlan(dftLndList[defLanLength - 1]);
        break;

      default:
        console.error("Default Landing not found");
        break;
    }
  }

  navigateToMarket() {
    this.router.navigate([`/market/`]);
  }
  navigateToPortfolio(portfolioId) {
    if (portfolioId) {
      this.router.navigate([`/portfolio/${portfolioId}`]);
    }
  }

  navigateToPlan(planId) {
    if (planId) {
      this.router.navigate([`/plan/${planId}`]);
    }
  }
}
