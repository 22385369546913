import { PortfolioAllocationComponent } from './portfolio/portfolio-allocation/portfolio-allocation.component';
import { PortfolioGroupComponent } from './portfolio-group/portfolio-group.component';
import { AppRoutingComponent } from './landing/app-routing/app-routing.component';

import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MarketComponent } from './market/market.component';
import { AuthGuardService } from './old/_services/_authGuard.service';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PlanComponent } from './plan/plan.component';
import { ProgramComponent } from './program/program.component';
import { UpfrontPlanningComponent } from './plan/upfront-planning/upfront-planning.component';
import { ScenarioPlanningComponent } from './plan/scenario-planning/scenario-planning.component';
import { ScenarioPlanningCtComponent } from './program/scenario-planning-ct/scenario-planning-ct.component';
import { AnnualMediaCalendarComponent } from './common/components/annual-media-calendar/annual-media-calendar.component';
import { ErrorComponent } from './common/components/error/error.component';
import { AccessDeniedComponent } from './common/components/accessDenied/accessDenied.component';
import { CampaignSummaryComponent } from './program/campaign-summary/campaign-summary/campaign-summary.component';
import { AppComponent } from "./app.component";

// user routings
const routes: Route[] = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'login'
  // },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  },

  {
    path: 'appRouting',
    component: AppRoutingComponent,    
    canActivate: [AuthGuardService]
  },
  
  {
    path: 'market',
    component: MarketComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'market/:id',
    component: MarketComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'portfolio/:id',
    component: PortfolioComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'portfolioAllocation/:id',
    component: PortfolioAllocationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'portfolioAllocation/:id/:year',//
    component: PortfolioAllocationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'portfolioGroup/:id',
    component: PortfolioGroupComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'plan/:id',
    component: PlanComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'plan/:id/:year',//new
    component: PlanComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'program/:id',
    component: ProgramComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path:'campaignSummary/:id',
    component: CampaignSummaryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'upfrontPlanning/:id/:year',//new
    component: UpfrontPlanningComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'upfrontPlanning/:id',
    component: UpfrontPlanningComponent, 
    canActivate: [AuthGuardService]
  },
  {
    path: 'scenarioPlanning/:id',
    component: ScenarioPlanningComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'scenarioPlanning/:id/:year',
    component: ScenarioPlanningComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'commsTask',
    component: ScenarioPlanningCtComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'commsTask/:id',
    component: ScenarioPlanningCtComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'annualMediaCalendar/:routeName/:id',
    component: AnnualMediaCalendarComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'error',
    component: ErrorComponent    
  },
  {
    path: 'accessDenied',
    component: AccessDeniedComponent    
  } 
  // {
  //   path: 'portfolioV3',
  //   loadChildren: () => import('./portfolio-v3/porfolio-v3.module').then(m => m.PortfolioModule),
  //   canActivate: [AuthGuardService]
  // },

  /*,

  // New dynamic routing
  // ======================================================
  // {
  //   path: ':brand',
  //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuardService]
  // },

  {
    path: 'market',
    loadChildren: () => import('./market/market.module').then(m => m.MarketModule),
    //component: MarketComponent, 
    canActivate: [AuthGuardService]
  },

  {
    path: 'portfolioV3',
    loadChildren: () => import('./portfolio-v3/porfolio-v3.module').then(m => m.PortfolioModule),
    canActivate: [AuthGuardService]
  },

 
  // ======================================================
  // New dynamic routing end

  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload' 
    })
  ],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
